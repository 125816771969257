// Import React stuff
import React from 'react';
import { Tooltip } from 'react-tooltip';

// Import icons, images
import info from '../assets/images/icons/info-icon.svg';

// Import helper functions
import { importAll } from '../utils/helpers';

// Import animation, motion
import { motion } from 'framer-motion';
import { progressVariants } from '../utils/animations';

const ProgressBar = ({ value, max, name, toolId, toolContent, imgSrcSm, imgSrcLg, delay }) => {
  const convertToPercent = (num, max) => {
    const percent = (num / max) * 100;
    return Math.round(percent);
  };

  const percentValue = convertToPercent(value, max);

  const barVariants = {
    hidden: {
      width: '0%',
    },
    visible: {
      width: `${percentValue}%`,
    },
  };

  return (
    <>
      <tr>
        <td className="small-cell"></td>
        <td className="name-cell">
          {' '}
          <h3 className="text-yellow mb-1 mb-lg-2 mt-1 mb-lg-2">
            {name}
            <img
              src={info}
              alt="info-icon"
              data-tooltip-id={toolId}
              data-tooltip-content={toolContent}
              data-tooltip-place="right"
              data-tooltip-offset={6}
            />
          </h3>
        </td>
        <td className="large-cell"></td>
      </tr>
      <tr>
        <td className="small-cell">
          <img src={imgSrcSm} alt="level-start" />
        </td>
        <td className="name-cell">
          {' '}
          <div className="progress">
            <motion.div
              className="progress-bar"
              style={{
                width: `${percentValue}%`,
                background: 'linear-gradient(90deg, #fff49a 0%, #fff200 100%)',
              }}
              role="progressbar"
              aria-valuenow={percentValue}
              aria-valuemin="0"
              initial="hidden"
              animate="visible"
              variants={barVariants}
              transition={{ delay }}
            ></motion.div>
          </div>
        </td>
        <td className="large-cell">
          {' '}
          <img src={imgSrcLg} alt="level-end" />
        </td>
      </tr>

      <Tooltip id={toolId} className="bar-tooltip" />
    </>
  );
};

const Bars = ({ product, mobile }) => {
  const images = importAll(require.context('../assets/images/icons', false, /\.(png|jpe?g|svg)$/));

  return (
    <div className="bars-container">
      <div className="bars-wrapper">
        <table>
          <motion.tbody
            className="p-0 m-0"
            variants={progressVariants}
            initial="hidden"
            animate="visible"
          >
            <ProgressBar
              value={product.productInfo.žarmojster}
              max={4}
              name={'Žar mojster'}
              toolId={'zarmojster'}
              toolContent={
                'Žar mojster prikazuje tvoje BBQ veščine in kako dobro se znajdeš pri razbeljeni žerjavici.'
              }
              imgSrcSm={images['result-icon1.svg']}
              imgSrcLg={images['result-icon1.svg']}
              delay={0}
            />
            <ProgressBar
              value={product.productInfo.legendalevel}
              max={4}
              name={'Legenda level'}
              toolId={'legenda'}
              toolContent={
                'Legenda level sporoča, kako priljubljen si med ostalimi gosti na pikniku.'
              }
              imgSrcSm={images['result-icon2.svg']}
              imgSrcLg={images['result-icon2.svg']}
              delay={0.2}
            />
            <ProgressBar
              value={product.productInfo.piknikstresnivo}
              max={4}
              name={'Piknik stres nivo'}
              toolId={'stres'}
              toolContent={
                'Piknik stres nivo ponazarja, koliko sivih las ti povzroča udeležba na pikniku.'
              }
              imgSrcSm={images['result-icon3.svg']}
              imgSrcLg={images['result-icon3.svg']}
              delay={0.4}
            />
            <ProgressBar
              value={product.productInfo.lakotometer}
              max={4}
              name={'Lakotometer'}
              toolId={'lakometer'}
              toolContent={'Lakotometer kaže, kako močno ti na pikniku kruli v trebuhu.'}
              imgSrcSm={images['result-icon4-sm.svg']}
              imgSrcLg={images['result-icon4.svg']}
              delay={0.6}
            />
          </motion.tbody>
        </table>
      </div>
    </div>
  );
};

export default Bars;
