// Import Redux stuff
import { configureStore } from '@reduxjs/toolkit';
//import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

// Import reducers
import gameSlice from './gameSlice';
import userSlice from './userSlice';

const reducers = combineReducers({
  games: gameSlice,
  users: userSlice,
});

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  //middleware: [thunk],

  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware({
      serializableCheck: false,
    }).concat([thunk]),
});

export default store;
