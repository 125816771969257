// Import React stuff
import React from 'react';

const Products = ({ product1, product2, product3, product4 }) => {
  return (
    <div className="products-wrapper">
      <h2 className="mb-3">Izdelki, ki ne smejo manjkati na tvojem pikniku:</h2>
      <div className="row products-row g-0">
        <div className="col-md-6 col-lg-3 product">
          <img src={product1} alt="product1" />
        </div>
        <div className="col-md-6 col-lg-3 product">
          <img src={product2} alt="product2" />
        </div>
        <div className="col-md-6 col-lg-3 product">
          <img src={product3} alt="product3" />
        </div>
        <div className="col-md-6 col-lg-3 product">
          <img src={product4} alt="product4" />
        </div>
      </div>
      <div className="mt-3">
        <a
          href="https://www.lidl.si/c/sveze-za-vsakega-gosta/c3456"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            onClick={() => window.plausible('CTA_izdelki')}
            className="btn btn-yellow text-blue"
          >
            PREVERI CELOTNO PONUDBO
          </button>
        </a>
      </div>
    </div>
  );
};

export default Products;
