// Import React stuff
import React, { useState } from 'react';

// Import redux stuff
import { useDispatch } from 'react-redux';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

// Import icons, images
import fresh from '../assets/images/fresh.png';
import soundOn from '../assets/images/icons/sound-on.svg';
import soundOff from '../assets/images/icons/sound-off.svg';

// Import components
import Progress from '../components/Progress';
import Preloader from '../components/Preloader';

const Questions = ({
  showPreloader,
  currentQuestion,
  setCurrentQuestion,
  filterCurrentQuestion,
  handleAnswerOptionClick,
  quizApi,
  playSound,
  toggleSound,
}) => {
  const dispatch = useDispatch();

  const [animateAnswer, setAnimateAnswer] = useState(false);

  //console.log(animateAnswer);

  return (
    <>
      {showPreloader && <Preloader />}
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`wrapper quiz-questions w-100 p-2 d-flex justify-content-center align-items-start align-items-sm-center bg-transparent position-relative`} //h-100
      >
        <div id="explosion" className="explosion-overlay"></div>
        <motion.img variants={child} src={fresh} alt="fresh" className="fresh-quest" />
        <motion.button variants={child} onClick={toggleSound} className="btn sound-icon">
          {playSound ? (
            <img src={soundOn} alt="sound-on" />
          ) : (
            <img src={soundOff} alt="sound-off" />
          )}
        </motion.button>

        <Progress currentQuestion={currentQuestion} quizApi={quizApi} />
        <div className="inner-wrapper h-100 text-center">
          {' '}
          <div className="question-content">
            <AnimatePresence exitBeforeEnter>
              <motion.div
                className="h-100"
                key={currentQuestion}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                onAnimationStart={() => {
                  setAnimateAnswer(true);
                }}
                onAnimationComplete={() => {
                  setAnimateAnswer(false);
                }}
              >
                {' '}
                <div className="h-100 d-flex align-items-end align-items-center flex-column">
                  <div className="pt-2 pt-md-5">
                    <div className="lead">
                      {' '}
                      <motion.p variants={child} className="text-white question-lead fw-normal">
                        {filterCurrentQuestion.questionDescription}
                      </motion.p>
                      <motion.p
                        variants={child}
                        className="text-yellow question-text fw-bold mb-1 py-2 px-2"
                      >
                        {filterCurrentQuestion.questionText}
                      </motion.p>
                    </div>
                    <div
                      className={`row g-0 ${
                        filterCurrentQuestion.type === 'img'
                          ? 'image-question-answer-wrapper'
                          : 'text-question-answer-wrapper'
                      }`}
                    >
                      {filterCurrentQuestion.answerOptions.map((answerOption, index) => (
                        <motion.div
                          variants={child}
                          key={index}
                          className={` ${
                            answerOption.answerImg
                              ? 'col-6 col-md image-answer-wrapper'
                              : 'col-12 col-md-6 text-answer-wrapper '
                          }`}
                          style={{
                            pointerEvents: animateAnswer ? 'none' : 'auto',
                          }}
                        >
                          <button
                            id={`answer-${index}`}
                            className={`btn btn-question p-options ${
                              answerOption.answerImg ? 'image-answer' : 'text-answer'
                            }`}
                            onClick={() => {
                              handleAnswerOptionClick(
                                answerOption.path,
                                answerOption.productTags,
                                answerOption.answerText,
                                answerOption.answerEmoji,
                                index
                              );
                            }}
                          >
                            {/* Show image if question answerImg true */}
                            {answerOption.answerImg ? (
                              <>
                                <div className="d-flex align-items-center justify-content-center">
                                  {' '}
                                  <img
                                    src={require(`../assets/images/questions/${answerOption.answerImg}`)}
                                    alt={answerOption.answerImg}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <span>{answerOption.answerText}</span>
                              </>
                            )}
                          </button>
                        </motion.div>
                      ))}
                    </div>

                    <motion.div
                      variants={child}
                      className="note d-flex align-items-center mx-auto justify-content-center"
                    >
                      <div className="text text-center quest-info">
                        <p
                          className="mb-0 mb-md-4"
                          style={{
                            display: filterCurrentQuestion.questionInfo ? 'block' : 'none',
                          }}
                        >
                          {filterCurrentQuestion.questionInfo}
                        </p>
                      </div>
                    </motion.div>

                    {currentQuestion > 1 && (
                      <motion.button
                        variants={child}
                        onClick={() => dispatch(setCurrentQuestion(currentQuestion - 1))}
                        className="btn btn-back"
                      >
                        <i className="bi bi-arrow-left"></i>&nbsp;Nazaj
                      </motion.button>
                    )}
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Questions;
