import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showIntro: true,
  showProducts: false,
  showPreloader: false,
  currentQuestion: 1,
  chosenProducts: [],
  chosenAnswer: [],
  isLoading: false,
  playSound: false,

  error: null,
};

export const gameSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setShowIntro: (state, action) => {
      state.showIntro = action.payload;
    },

    setShowProducts: (state, action) => {
      state.showProducts = action.payload;
    },

    setShowPreloader: (state, action) => {
      state.showPreloader = action.payload;
    },

    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },

    setChosenProducts: (state, action) => {
      state.chosenProducts.push(...action.payload);
    },

    setChosenAnswer: (state, action) => {
      state.chosenAnswer.push(...action.payload);
    },

    setPlaySound: (state, action) => {
      state.playSound = action.payload;
    },

    resetGame: () => initialState,
  },
  extraReducers: (builder) => {
    //builder;
    // .addCase(getAllGames.pending, (state) => {
    //   state.isLoading = true;
    //   state.error = null;
    // })
    // .addCase(getAllGames.fulfilled, (state, action) => {
    //   console.log(state.games);
    //   state.games = action.payload;
    //   state.isLoading = false;
    // })
    // .addCase(getAllGames.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // });
  },
});

export const {
  setShowIntro,
  setShowProducts,
  setShowPreloader,
  setCurrentQuestion,
  setChosenProducts,
  setChosenAnswer,
  setPlaySound,

  resetGame,
} = gameSlice.actions;

export default gameSlice.reducer;
