// Import React stuff
import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';

// Import motion, animations
import { AnimatePresence, motion } from 'framer-motion';
import { modalDrop, child, containerVariants } from '../utils/animations';

// Import icons, images
import close from '../assets/images/icons/close.svg';
import fresh from '../assets/images/fresh.png';

// Import helper functions
import { importAll } from '../utils/helpers';

const Prizes = ({ handleClose, quizIntro }) => {
  const images = importAll(
    require.context('../assets/images/prizes', false, /\.(png|jpe?g|svg|gif)$/)
  );

  const mobile = useMediaQuery('only screen and (max-width: 767px)');

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      role="document"
      variants={modalDrop}
      className="modal modal-fullscreen"
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <AnimatePresence exitBeforeEnter>
        <motion.img variants={child} src={fresh} alt="fresh" className="fresh-prize" />
        <motion.button variants={child} className="btn exit-button" onClick={handleClose}>
          <img src={close} alt="close" />
        </motion.button>

        <motion.div variants={containerVariants} className="modal-content">
          <div className="pb-5">
            <motion.div variants={child} className="p-0 m-0">
              <h1 className="mb-4">{quizIntro.prizeH1}</h1>
              <h2>{quizIntro.prizeH2}</h2>
              <div className="prize-descr">
                <p>{quizIntro.prizeDescription1}</p>
              </div>
              <p>{quizIntro.prizeDescription2}</p>
            </motion.div>
            <motion.div variants={child} className="prize-wrapper">
              <div className="row justify-content-center">
                {mobile ? (
                  <div className="prize-card col-md-4">
                    <h2>{quizIntro.prize1.name}</h2>
                    <div className="prize-media">
                      <img
                        src={images[`${quizIntro.prize1.image2}`]}
                        alt={images[`${quizIntro.prize1.image2}`]}
                      />
                    </div>
                    <h3>{quizIntro.prize1.content}</h3>
                  </div>
                ) : (
                  <div className="main-prize col">
                    <h2>{quizIntro.prize1.name}</h2>
                    <div className="main-media">
                      {' '}
                      <img
                        src={images[`${quizIntro.prize1.image}`]}
                        alt={images[`${quizIntro.prize1.image}`]}
                      />
                    </div>
                    <h3>{quizIntro.prize1.content}</h3>
                  </div>
                )}
              </div>
              <div className="row justify-content-center">
                <div className="prize-card col-md-4">
                  <h2>{quizIntro.prize2.name}</h2>
                  <div className="prize-media">
                    <img
                      src={images[`${quizIntro.prize2.image}`]}
                      alt={images[`${quizIntro.prize2.image}`]}
                    />
                  </div>
                  <h3>{quizIntro.prize2.content}</h3>
                </div>
                <div className="prize-card col-md-4">
                  <h2>{quizIntro.prize3.name}</h2>
                  <div className="prize-media">
                    {' '}
                    <img
                      src={images[`${quizIntro.prize3.image}`]}
                      alt={images[`${quizIntro.prize3.image}`]}
                    />
                  </div>
                  <h3>{quizIntro.prize3.content}</h3>
                </div>
                <div className="prize-card col-md-4">
                  <h2>{quizIntro.prize4.name}</h2>
                  <div className="prize-media">
                    <img
                      src={images[`${quizIntro.prize4.image}`]}
                      alt={images[`${quizIntro.prize4.image}`]}
                    />
                  </div>
                  <h3>{quizIntro.prize4.content}</h3>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="prize-card col-md-4">
                  <h2>{quizIntro.prize5.name}</h2>
                  <div className="prize-media">
                    {' '}
                    <img
                      src={images[`${quizIntro.prize5.image}`]}
                      alt={images[`${quizIntro.prize5.image}`]}
                    />
                  </div>
                  <h3>{quizIntro.prize5.content}</h3>
                </div>
                <div className="prize-card col-md-4">
                  <h2>{quizIntro.prize6.name}</h2>
                  <div className="prize-media">
                    <img
                      src={images[`${quizIntro.prize6.image}`]}
                      alt={images[`${quizIntro.prize6.image}`]}
                    />
                  </div>
                  <h3>{quizIntro.prize6.content}</h3>
                </div>
                <div className="prize-card col-md-4">
                  <h2>{quizIntro.prize7.name}</h2>
                  <div className="prize-media">
                    {' '}
                    <img
                      src={images[`${quizIntro.prize7.image}`]}
                      alt={images[`${quizIntro.prize7.image}`]}
                    />
                  </div>
                  <h3>{quizIntro.prize7.content}</h3>
                </div>
              </div>
            </motion.div>
            <div className="btn-wrap">
              {' '}
              <motion.button
                variants={child}
                className="btn btn-lg w-100 btn-yellow text-blue"
                onClick={handleClose}
              >
                ZAPRI
              </motion.button>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Prizes;
