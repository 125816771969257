// Import React stuff
import React from 'react';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

// Import icons, images
import fresh from '../assets/images/fresh.png';
import soundOn from '../assets/images/icons/sound-on.svg';
import soundOff from '../assets/images/icons/sound-off.svg';

// Import components
import OptForm from '../components/OptForm';
import Prizes from '../components/Prizes';

// Import helper functions
import { isValidEmail } from '../utils/helpers';

const OptinEnd = ({
  quizIntro,
  handleEndGuest,
  handleEndUser,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  unvalidEmail,
  setUnvalidEmail,
  reusedEmail,
  openPrizeModal,
  closeModal,
  openModal,
  playSound,
  toggleSound,
}) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));

    // Clear the corresponding error when the user starts typing or checks the checkbox
    if (name === 'name' && formErrors.name) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: false,
      }));
    }

    if (name === 'email') {
      if (formErrors.email) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));
      }

      // Check if the email is valid
      const isEmailValid = isValidEmail(value);
      setUnvalidEmail(!isEmailValid);
    }

    if (name === 'conditions' && formErrors.conditions) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        conditions: false,
      }));
    }

    if (name === 'processingCond' && formErrors.processConditions) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        processConditions: false,
      }));
    }

    if (name === 'subscribe') {
      if (checked) {
        window.plausible('subscribe');
      }
    }
  };

  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`wrapper quiz-optin w-100 p-2 d-flex justify-content-center align-items-start align-items-sm-center bg-transparent position-relative`}
      >
        <motion.img variants={child} src={fresh} alt="fresh" className="fresh-opt" />
        <motion.button variants={child} onClick={toggleSound} className="btn sound-optin">
          {playSound ? (
            <img src={soundOn} alt="sound-on" />
          ) : (
            <img src={soundOff} alt="sound-off" />
          )}
        </motion.button>
        <div className="inner-wrapper h-100 text-center">
          {' '}
          <div className="optin-content">
            <AnimatePresence exitBeforeEnter>
              <motion.div
                className="h-100"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <motion.h1 variants={child} className="h1-optin">
                  Zadnja priložnost za prijavo <br />
                  in sodelovanje v nagradnem žrebu!
                </motion.h1>
                <div className="info">
                  <motion.p
                    variants={child}
                    className="text-white info fw-normal mb-1 py-2"
                    dangerouslySetInnerHTML={{ __html: quizIntro.optinTextEnd }}
                  ></motion.p>
                </div>
                <OptForm
                  quizIntro={quizIntro}
                  formData={formData}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  unvalidEmail={unvalidEmail}
                  reusedEmail={reusedEmail}
                />
                <motion.div variants={child} className="optin-btn-wrapper">
                  <button onClick={handleEndUser} className="btn btn-yellow text-blue optin-btn">
                    REZULTATI KVIZA
                  </button>
                  <button
                    onClick={handleEndGuest}
                    className="btn btn-sm btn-outline-white text-white guest-btn"
                  >
                    KONČAJ KOT GOST
                  </button>
                  <span className="mt-3 mb-2">
                    (Toda brez možnosti za osvojitev{' '}
                    <span
                      className="mb-1 prize"
                      onClick={() => (openPrizeModal ? closeModal() : openModal())}
                    >
                      <b>nagrad</b>
                    </span>
                    .)
                  </span>
                </motion.div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
      {openPrizeModal && (
        <Prizes handleClose={closeModal} openPrizeModal={openPrizeModal} quizIntro={quizIntro} />
      )}
    </>
  );
};

export default OptinEnd;
