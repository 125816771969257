// Import React stuff
import React from 'react';

// Import animation, motion
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

// Import icons, images
import imgUsp01 from '../assets/images/icons/usp01.svg';
import imgUsp02 from '../assets/images/icons/usp02.svg';
import imgUsp03 from '../assets/images/icons/usp03.svg';
import fresh from '../assets/images/fresh.png';
import intro from '../assets/images/intro-img.png';
import introMobile from '../assets/images/intro-img-mobile.png';

// Import components
import Prizes from '../components/Prizes';

const Intro = ({ quizIntro, startQuiz, openPrizeModal, closeModal, openModal, mobile }) => {
  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="wrapper quiz-intro w-100 d-flex position-relative" //h-100
      >
        <motion.img variants={child} src={fresh} alt="fresh" className="fresh-img" />

        <div className="intro-image-wrap col-md-5">
          <div style={{ display: mobile ? 'none' : 'flex' }} className="intro-img-wrap">
            <motion.img variants={child} src={intro} alt="intro-image" className="intro-image" />
          </div>
        </div>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="text-center intro-wrapper align-top col-md-7"
        >
          <motion.h1 variants={child} className="h1-welcome">
            <span className="text-white">{quizIntro.label}</span>
            {quizIntro.heading1}
          </motion.h1>
          <div className="pb-2">
            <div className="text-center mx-auto mt-2">
              <div className="intro-description">
                <motion.p variants={child}>{quizIntro.introDescription}</motion.p>
              </div>
              <div className="row g-0">
                <motion.div variants={child} className="col-4 usps">
                  <img className="icon" src={imgUsp01} alt={imgUsp01} />
                  <p>{quizIntro.usp1}</p>
                </motion.div>
                <motion.div variants={child} className="col-4 usps">
                  <img className="icon" src={imgUsp02} alt={imgUsp02} />
                  <p>{quizIntro.usp2}</p>
                </motion.div>
                <motion.div variants={child} className="col-4 usps">
                  <img className="icon" src={imgUsp03} alt={imgUsp03} />
                  <p>{quizIntro.usp3}</p>
                </motion.div>
              </div>
            </div>
            <motion.button
              variants={child}
              onClick={startQuiz}
              className="btn btn-yellow text-blue introscreen-btn"
            >
              {quizIntro.cta1}
            </motion.button>
            <motion.div variants={child} className="prizes d-flex">
              <span className="mb-1" onClick={() => (openPrizeModal ? closeModal() : openModal())}>
                {quizIntro.cta2}
              </span>
              <a
                className="cond"
                href={quizIntro.pravilaPogoji}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quizIntro.conditions}
              </a>
            </motion.div>
          </div>
          {mobile && (
            <div className="mobile-wrap">
              <motion.img
                variants={child}
                src={introMobile}
                alt="intro-image"
                className="intro-image-mobile"
              />
            </div>
          )}
        </motion.div>
      </motion.div>
      {openPrizeModal && (
        <Prizes handleClose={closeModal} openPrizeModal={openPrizeModal} quizIntro={quizIntro} />
      )}
    </>
  );
};

export default Intro;
