const Preloader = () => {
  return (
    <div className="showPreloader-container d-flex justify-content-center align-items-center">
      <div className="showPreloader-wrapper d-flex justify-content-center align-items-center p-3">
        <h3 className="h3-loader mb-3">Building the best product recommendation for you...</h3>
        <div className="loading-animation d-flex justify-content-center align-items-center mb-2">
          {/*  ANIM */}
        </div>
        <small className="pt-3">This can take couple of seconds.</small>
      </div>
    </div>
  );
};

export default Preloader;
