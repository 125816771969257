import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const API = axios.create({
  baseURL: `${API_ENDPOINT}/api`,
  headers: {
    'Content-type': 'application/json',
  },
});

export default API;
