// Import React stuff
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';

// Import actions
import {
  setShowIntro,
  setShowProducts,
  setShowPreloader,
  setCurrentQuestion,
  setChosenAnswer,
  setChosenProducts,
  setPlaySound,
  resetGame,
} from './store/gameSlice';
import { setUser, resetUser } from './store/userSlice';
import { createUser } from './store/actions';

// Import components
import Intro from './views/Intro';
import OptinStart from './views/OptinStart';
import OptinEnd from './views/OptinEnd';
import Questions from './views/Questions';
import Results from './views/Results';

// Import helper functions
import {
  isValidEmail,
  validateForm,
  triggerEmojiExplosion,
  showOverlay,
  hideOverlay,
} from './utils/helpers';

// Import styles, icons, images
import './assets/styles/style.scss';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';

// Import audio
import backgroundSound from './assets/audio/background_sound.wav';

// Import data
import quizIntro from './data/apiIntro.json';
import quizApi from './data/apiQuestions.json';
import quizProducts from './data/apiProducts.json';
import { setShowStartOptin, setShowEndOptin } from './store/userSlice';

function App() {
  const dispatch = useDispatch();

  const showIntro = useSelector((state) => state.games.showIntro);
  const showProducts = useSelector((state) => state.games.showProducts);
  const showPreloader = useSelector((state) => state.games.showPreloader);
  const currentQuestion = useSelector((state) => state.games.currentQuestion);
  const chosenProducts = useSelector((state) => state.games.chosenProducts);
  const playSound = useSelector((state) => state.games.playSound);

  const showStartOptin = useSelector((state) => state.users.showStartOptin);
  const showEndOptin = useSelector((state) => state.users.showEndOptin);
  const user = useSelector((state) => state.users.user);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    conditions: false,
    subscribe: false,
    processingCond: false,
  });

  // Validate email
  const [unvalidEmail, setUnvalidEmail] = useState(false);
  const [reusedEmail, setReusedEmail] = useState(false);

  // Errors
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    conditions: false,
    processConditions: false,
  });

  const mobile = useMediaQuery('only screen and (max-width: 767px)');

  // Move from intro page to optin
  const startQuiz = () => {
    dispatch(setShowIntro(false));
    dispatch(setPlaySound(true));
    window.plausible('start');
  };

  const submitOptinStart = () => {
    dispatch(setShowStartOptin(false));
    dispatch(setShowProducts(false));
    dispatch(setCurrentQuestion(1));
    dispatch(setChosenAnswer([]));
    dispatch(setChosenProducts([]));
  };

  // Submit start optin
  const handleStartUser = async (e) => {
    e.preventDefault();

    // Perform form validation
    const errors = validateForm(formData);
    setFormErrors(errors);

    // Check if the email is valid
    const isEmailValid = isValidEmail(formData.email);
    setUnvalidEmail(!isEmailValid);

    // Submit the form if there are no errors and all fields are filled
    if (
      Object.keys(errors).length === 0 &&
      isEmailValid &&
      formData.name &&
      formData.conditions &&
      formData.processingCond
    ) {
      // Save the form data or dispatch an action
      //console.log('Form data:', formData);

      dispatch(createUser(formData))
        .then((action) => {
          const response = action.payload;
          if (response && response.status === 201) {
            // User created successfully
            setFormData({
              name: '',
              email: '',
              conditions: false,
              subscribe: false,
              processingCond: false,
            });
            dispatch(setShowEndOptin(false));
            submitOptinStart();
            window.plausible('optin_start');
          } else {
            // Reused email or request failed
            console.log('Email reused or request failed');
            setReusedEmail(true);
            // Reset the reusedEmail state after 9 seconds
            setTimeout(() => {
              setReusedEmail(false);
            }, 9000);
          }
        })
        .catch((error) => {
          // Handle error
          console.log('Error:', error);
        });
    }
  };

  const handleStartGuest = () => {
    dispatch(setShowEndOptin(true));
    setFormErrors(false);
    setUnvalidEmail(false);
    submitOptinStart();

    // Update the userSubmitted status in the Redux state
    dispatch(setUser(false));
  };

  // Submit end optin
  const handleEndUser = async () => {
    // Perform form validation
    const formErrors = validateForm(formData);

    if (Object.keys(formErrors).length > 0) {
      setFormErrors(formErrors);
    } else {
      const isEmailValid = isValidEmail(formData.email);
      if (formData.email.trim() !== '' && !isEmailValid) {
        setUnvalidEmail(true);
      } else {
        // Save the form data or dispatch an action
        //console.log('Form data:', formData);

        dispatch(createUser(formData))
          .then((action) => {
            const response = action.payload;
            if (response && response.status === 201) {
              // User created successfully
              setTimeout(() => {
                //console.log(chosenProducts);
                dispatch(setShowIntro(true));
                dispatch(setShowPreloader(false));
                //dispatch(setPlaySound(false));
                dispatch(setShowEndOptin(false));
                dispatch(setShowProducts(true));
                window.plausible('optin_end');

                setTimeout(() => {
                  window.plausible('result');
                  dispatch(setShowIntro(false));
                }, 4000);
              }, 1000);
            } else {
              // Reused email or request failed
              console.log('Email reused or request failed');
              setReusedEmail(true);
              // Reset the reusedEmail state after 9 seconds
              setTimeout(() => {
                setReusedEmail(false);
              }, 9000);
            }
          })
          .catch((error) => {
            // Handle error
            console.log('Error:', error);
          });
      }
    }
  };

  const handleEndGuest = () => {
    setTimeout(() => {
      //console.log(chosenProducts);
      dispatch(setShowIntro(true));
      dispatch(setShowPreloader(false));
      //dispatch(setPlaySound(false));
      dispatch(setShowEndOptin(false));

      setTimeout(() => {
        dispatch(setShowProducts(true));

        setTimeout(() => {
          window.plausible('result');
          dispatch(setShowIntro(false));
        }, 4000);
      }, 1000);
    }, 1000); //1000
  };

  const handleAnswerOptionClick = (path, productTags, answerText, answerEmoji, index) => {
    window.scrollTo(0, 0);

    const nextQuestion = path;

    // Add product to array based on chosen answer
    dispatch(setChosenProducts([productTags]));

    // // Add answer text to array
    dispatch(setChosenAnswer([answerText]));

    // Emoji explosion
    if (answerEmoji) {
      showOverlay(); // Show the explosion overlay
      const element = document.querySelector(`#answer-${index}`);
      const size = mobile ? 20 : 40;
      const count = mobile ? 30 : 50;
      triggerEmojiExplosion(element, answerEmoji, size, count);
    }

    // Check whether next question exists otherwise show optin/results
    if (nextQuestion <= quizApi.content.length) {
      setTimeout(() => {
        hideOverlay(); // Hide the explosion overlay
        dispatch(setCurrentQuestion(nextQuestion));
      }, 1000); //500
    } else if (!user) {
      setTimeout(() => {
        hideOverlay(); // Hide the explosion overlay
        dispatch(setShowProducts(true));
        dispatch(setShowEndOptin(true));
        dispatch(setShowIntro(false));
      }, 1000); //500
    } else {
      setTimeout(() => {
        //console.log(chosenProducts);
        dispatch(setShowIntro(true));
        dispatch(setShowPreloader(false));
        //dispatch(setPlaySound(false));
        dispatch(setShowProducts(true));

        setTimeout(() => {
          hideOverlay(); // Hide the explosion overlay
          window.plausible('result');
          dispatch(setShowIntro(false));
        }, 4000);
      }, 1000);
    }
  };

  // Find object of current question
  const filterCurrentQuestion = quizApi.content.find((obj) => obj.id === currentQuestion);

  // Check if we have 100% productTags match
  const filterProductsEvery = quizProducts.filter((item) => {
    let results = chosenProducts.every((tag) => {
      return item.productTags.includes(tag);
    });

    return results;
  });

  // Check if we have more than 1 productTags match
  quizProducts.map((item) => {
    item.score = 0;
    chosenProducts.forEach((n) => {
      if (item.productTags.includes(n)) {
        item.score++;
      } else {
      }
    });

    return item;
  });

  const filterProductsSome = quizProducts.filter((item) => {
    var result = chosenProducts.filter((n) => {
      return item.productTags.includes(n);
    });

    return result.length > 1; // Return a boolean value indicating if the condition is met
  });

  // If 100% match show that otherwise fallback to at least 1 match
  const filterProducts = () => {
    if (filterProductsEvery.length > 0) {
      return filterProductsEvery;
    } else {
      return filterProductsSome;
    }
  };

  const startAgain = () => {
    if (!user) {
      window.plausible('play_again');
      dispatch(resetUser());
      dispatch(setShowStartOptin(true));
    } else {
      window.plausible('play_again');
      dispatch(setShowStartOptin(false));
      dispatch(setShowEndOptin(false));
    }

    // Reset game but not the user
    setTimeout(() => {
      dispatch(resetGame());
    }, 1000);
  };

  // Reset states and clear localStorage when the tab is closed
  useEffect(() => {
    const handleAppClose = () => {
      // Clear sessionStorage when the tab/browser is closed
      sessionStorage.clear();
      dispatch(resetGame());
      dispatch(resetUser());
      localStorage.clear();
    };

    // Check if the page was reloaded by checking sessionStorage
    const isPageReloaded = sessionStorage.getItem('reloaded') != null;

    if (isPageReloaded) {
      console.log('Page was reloaded');
    } else {
      console.log('Page was not reloaded');
      // Add your specific actions for a new tab or initial page load here
    }

    // Set the sessionStorage to mark the page as reloaded
    sessionStorage.setItem('reloaded', 'yes');

    // Attach the beforeunload event listener only if the page is not reloaded
    if (!isPageReloaded) {
      window.addEventListener('beforeunload', handleAppClose);
    }

    return () => {
      // Remove the beforeunload event listener if it was attached
      if (!isPageReloaded) {
        window.removeEventListener('beforeunload', handleAppClose);
      }
    };
  }, [dispatch]);

  // Open/close prize modal
  const [openPrizeModal, setOpenPrizeModal] = useState(false);

  const closeModal = () => setOpenPrizeModal(false);
  const openModal = () => {
    window.plausible('nagrade');
    setOpenPrizeModal(true);
  };

  const toggleSound = () => {
    dispatch(setPlaySound(!playSound));
  };

  // Set bg volume
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.8;
    }
  }, []);

  return (
    <>
      {playSound && <audio id="bgSound" ref={audioRef} src={backgroundSound} loop autoPlay />}
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.5 }}
        transition={{ duration: 0.3 }}
        className="quiz d-flex justify-content-center"
      >
        <AnimatePresence exitBeforeEnter>
          {showProducts ? (
            showEndOptin ? (
              <OptinEnd
                quizIntro={quizIntro}
                handleEndGuest={handleEndGuest}
                handleEndUser={handleEndUser}
                formData={formData}
                setFormData={setFormData}
                setFormErrors={setFormErrors}
                formErrors={formErrors}
                setUnvalidEmail={setUnvalidEmail}
                unvalidEmail={unvalidEmail}
                reusedEmail={reusedEmail}
                openPrizeModal={openPrizeModal}
                closeModal={closeModal}
                openModal={openModal}
                playSound={playSound}
                toggleSound={toggleSound}
              />
            ) : (
              <Results
                setShowIntro={setShowIntro}
                showIntro={showIntro}
                startQuiz={startQuiz}
                filterProducts={filterProducts}
                chosenProducts={chosenProducts}
                startAgain={startAgain}
                mobile={mobile}
              />
            )
          ) : showIntro ? (
            <Intro
              startQuiz={startQuiz}
              quizIntro={quizIntro}
              openPrizeModal={openPrizeModal}
              closeModal={closeModal}
              openModal={openModal}
              mobile={mobile}
            />
          ) : showStartOptin ? (
            <OptinStart
              quizIntro={quizIntro}
              handleStartGuest={handleStartGuest}
              handleStartUser={handleStartUser}
              formData={formData}
              setFormData={setFormData}
              setFormErrors={setFormErrors}
              formErrors={formErrors}
              unvalidEmail={unvalidEmail}
              setUnvalidEmail={setUnvalidEmail}
              reusedEmail={reusedEmail}
              openPrizeModal={openPrizeModal}
              closeModal={closeModal}
              openModal={openModal}
              playSound={playSound}
              toggleSound={toggleSound}
            />
          ) : (
            <>
              <Questions
                showPreloader={showPreloader}
                currentQuestion={currentQuestion}
                setCurrentQuestion={setCurrentQuestion}
                filterCurrentQuestion={filterCurrentQuestion}
                handleAnswerOptionClick={handleAnswerOptionClick}
                quizApi={quizApi}
                playSound={playSound}
                toggleSound={toggleSound}
              />
            </>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
}

export default App;
