import Lottie from 'lottie-react';

const Loader = () => {
  // Define the new color values
  const color1 = [255, 237, 0, 1]; //yellow
  const color2 = [0, 30, 100, 1]; //blue

  return (
    <div className="loader-wrapper">
      <Lottie
        style={{ height: '300px', width: '300px' }}
        animationData={{
          v: '5.9.0',
          fr: 25,
          ip: 0,
          op: 750,
          w: 500,
          h: 500,
          nm: 'Grill',
          ddd: 0,
          assets: [],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 1,
              nm: 'CONTROLS',
              sr: 1,
              ks: {
                o: { a: 0, k: 0, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [250, 250, 0], ix: 2, l: 2 },
                a: { a: 0, k: [250, 250, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: 'Color_1',
                  np: 3,
                  mn: 'ADBE Color Control',
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 2,
                      nm: 'Color',
                      mn: 'ADBE Color Control-0001',
                      ix: 1,
                      v: { a: 0, k: color1, ix: 1 },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: 'Color_2',
                  np: 3,
                  mn: 'ADBE Color Control',
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 2,
                      nm: 'Color',
                      mn: 'ADBE Color Control-0001',
                      ix: 1,
                      v: { a: 0, k: color2, ix: 1 },
                    },
                  ],
                },
              ],
              sw: 500,
              sh: 500,
              sc: '#000000',
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: 'Shape Layer 5',
              parent: 4,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 79, ix: 10 },
                p: { a: 0, k: [-64.764, -230.964, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [105, 105, 100], ix: 6, l: 2 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: 'Fill',
                  np: 9,
                  mn: 'ADBE Fill',
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: 'Fill Mask',
                      mn: 'ADBE Fill-0001',
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: 'All Masks',
                      mn: 'ADBE Fill-0007',
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: 'Color',
                      mn: 'ADBE Fill-0002',
                      ix: 3,
                      v: {
                        a: 0,
                        k: [0, 30 / 255, 100 / 255, 1],
                      },
                    },
                    {
                      ty: 7,
                      nm: 'Invert',
                      mn: 'ADBE Fill-0006',
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: 'Horizontal Feather',
                      mn: 'ADBE Fill-0003',
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: 'Vertical Feather',
                      mn: 'ADBE Fill-0004',
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: 'Opacity',
                      mn: 'ADBE Fill-0005',
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [40.153, -19.861],
                            [35.304, -21.901],
                          ],
                          o: [
                            [-40.153, 19.861],
                            [-35.304, 21.901],
                          ],
                          v: [
                            [131.451, 3.55],
                            [40.047, -12.404],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [33.334, -16.724],
                            [27.467, -11.782],
                          ],
                          o: [
                            [-33.334, 16.724],
                            [-27.467, 11.782],
                          ],
                          v: [
                            [146.492, -78.322],
                            [60.017, -92.93],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 3',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 2,
                      ty: 'sh',
                      ix: 3,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [35.739, -14.122],
                            [33.982, -17.598],
                          ],
                          o: [
                            [-35.739, 14.122],
                            [-33.982, 17.598],
                          ],
                          v: [
                            [140.603, -38.039],
                            [47.539, -53.442],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 2',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 10, ix: 5 },
                      lc: 2,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-0.379, 0.311], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 1',
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 14,
                        s: [0],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 23,
                        s: [0],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 34.383,
                        s: [100],
                      },
                      { t: 37.3828125, s: [100] },
                    ],
                    ix: 1,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();',
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 14,
                        s: [0],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 32,
                        s: [100],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 34.383,
                        s: [100],
                      },
                      { t: 37.3828125, s: [100] },
                    ],
                    ix: 2,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();',
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
              ],
              ip: 14,
              op: 764,
              st: 13,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: 'Layer 2',
              parent: 4,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1.572, -1.966, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1.572, -1.966, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: 'Fill',
                  np: 9,
                  mn: 'ADBE Fill',
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: 'Fill Mask',
                      mn: 'ADBE Fill-0001',
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: 'All Masks',
                      mn: 'ADBE Fill-0007',
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: 'Color',
                      mn: 'ADBE Fill-0002',
                      ix: 3,
                      v: {
                        a: 0,
                        k: [0, 30 / 255, 100 / 255, 1],
                      },
                    },
                    {
                      ty: 7,
                      nm: 'Invert',
                      mn: 'ADBE Fill-0006',
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: 'Horizontal Feather',
                      mn: 'ADBE Fill-0003',
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: 'Vertical Feather',
                      mn: 'ADBE Fill-0004',
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: 'Opacity',
                      mn: 'ADBE Fill-0005',
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [5.55, -1.091],
                            [1.07, 5.552],
                            [0, 0],
                            [-5.906, 3.697],
                            [0, 0],
                          ],
                          o: [
                            [-5.566, 1.064],
                            [0, 0],
                            [6.744, -2.946],
                            [0, 0],
                            [1.078, 5.552],
                          ],
                          v: [
                            [112.091, 105.429],
                            [100.047, 97.314],
                            [84.757, 18.916],
                            [103.757, 8.959],
                            [120.212, 93.386],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [5.661, 0],
                            [0, 5.661],
                            [0, 0],
                            [-5.108, 0],
                            [-1.827, 0.04],
                          ],
                          o: [
                            [0, 5.661],
                            [-5.668, 0],
                            [0, 0],
                            [4.925, 0.286],
                            [1.848, 0],
                            [0, 0],
                          ],
                          v: [
                            [7.068, 134.317],
                            [-3.202, 144.574],
                            [-13.473, 134.317],
                            [-13.473, 34.301],
                            [1.565, 34.765],
                            [7.068, 34.683],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 2',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 2,
                      ty: 'sh',
                      ix: 3,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [5.552, 1.05],
                            [-1.091, 5.552],
                            [0, 0],
                            [-6.697, -3.437],
                          ],
                          o: [
                            [-1.084, 5.552],
                            [-5.557, -1.091],
                            [0, 0],
                            [5.756, 4.242],
                            [0, 0],
                          ],
                          v: [
                            [-106.451, 97.314],
                            [-118.495, 105.429],
                            [-126.611, 93.386],
                            [-109.042, 3.217],
                            [-90.356, 14.757],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 3',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 3,
                      ty: 'sh',
                      ix: 4,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [9.656, 66.027],
                            [-15.017, -3.887],
                            [-0.191, -0.067],
                            [-15.951, -1.909],
                            [-0.3, 0],
                            [-0.067, 0],
                            [-14.668, -0.559],
                            [-0.04, 0],
                            [-0.028, 0],
                            [-7.175, 0],
                            [-7.175, 0.26],
                            [-0.049, 0],
                            [-0.068, 0.013],
                            [-13.782, 1.623],
                            [-0.082, 0],
                            [-0.279, 0.068],
                            [-15.208, 10.733],
                            [67.78, 0],
                          ],
                          o: [
                            [8.116, 5.728],
                            [0.176, 0.082],
                            [12.957, 3.314],
                            [0.286, 0.068],
                            [0.082, 0],
                            [13.796, 1.623],
                            [0.049, 0],
                            [0.028, 0],
                            [7.187, 0.26],
                            [7.161, 0],
                            [0.04, 0],
                            [0.06, 0],
                            [14.648, -0.546],
                            [0.075, 0],
                            [0.3, 0],
                            [34.739, -4.173],
                            [-9.663, 66.027],
                            [-67.78, 0],
                          ],
                          v: [
                            [-144.164, -70.557],
                            [-108.687, -56.1],
                            [-108.115, -55.936],
                            [-64.292, -48.066],
                            [-63.447, -47.875],
                            [-63.229, -47.915],
                            [-20.231, -44.643],
                            [-20.102, -44.629],
                            [-20.019, -44.629],
                            [1.565, -44.234],
                            [23.108, -44.629],
                            [23.238, -44.601],
                            [23.435, -44.656],
                            [66.364, -47.915],
                            [66.576, -47.875],
                            [67.421, -48.066],
                            [147.307, -70.557],
                            [1.565, 25.286],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 4',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 4,
                      ty: 'sh',
                      ix: 5,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-21.877, 6.302],
                            [0, 0],
                            [0, 6.411],
                          ],
                          o: [
                            [0, 0],
                            [-21.877, -6.302],
                            [0, -6.409],
                          ],
                          v: [
                            [-111.511, -107.178],
                            [-111.511, -66.615],
                            [-145.377, -86.898],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 5',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 5,
                      ty: 'sh',
                      ix: 6,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-12.739, 1.677],
                            [0, 0],
                            [9.807, 2.359],
                          ],
                          o: [
                            [9.807, -2.36],
                            [0, 0],
                            [-12.739, -1.677],
                            [0, 0],
                          ],
                          v: [
                            [-102.046, -109.674],
                            [-68.18, -115.813],
                            [-68.18, -57.995],
                            [-102.046, -64.12],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 6',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 6,
                      ty: 'sh',
                      ix: 7,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-5.443, 0.246],
                            [0, 0],
                            [10.475, 1.159],
                          ],
                          o: [
                            [2.441, -0.26],
                            [0, 0],
                            [-12.092, -0.518],
                            [0, 0],
                          ],
                          v: [
                            [-58.7, -116.958],
                            [-24.833, -119.508],
                            [-24.833, -54.286],
                            [-58.7, -56.836],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 7',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 7,
                      ty: 'sh',
                      ix: 8,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-3.3, -0.082],
                            [0, 0],
                            [5.789, 0],
                            [5.496, 0.151],
                          ],
                          o: [
                            [4.249, -0.123],
                            [0, 0],
                            [-5.503, 0.151],
                            [-5.791, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.361, -119.849],
                            [18.491, -119.849],
                            [18.491, -53.945],
                            [1.565, -53.713],
                            [-15.361, -53.945],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 8',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 8,
                      ty: 'sh',
                      ix: 9,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-3.941, -0.423],
                            [0, 0],
                            [12.084, -0.518],
                          ],
                          o: [
                            [3.437, 0.149],
                            [0, 0],
                            [-10.468, 1.159],
                            [0, 0],
                          ],
                          v: [
                            [27.977, -119.508],
                            [61.836, -116.958],
                            [61.836, -56.836],
                            [27.977, -54.286],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 9',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 9,
                      ty: 'sh',
                      ix: 10,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-9.793, -2.36],
                            [0, 0],
                            [12.726, -1.677],
                          ],
                          o: [
                            [12.726, 1.677],
                            [0, 0],
                            [-9.793, 2.359],
                            [0, 0],
                          ],
                          v: [
                            [71.322, -115.813],
                            [105.176, -109.674],
                            [105.176, -64.12],
                            [71.322, -57.995],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 10',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 10,
                      ty: 'sh',
                      ix: 11,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, -6.409],
                            [21.863, -6.302],
                          ],
                          o: [
                            [21.863, 6.288],
                            [0, 6.411],
                            [0, 0],
                          ],
                          v: [
                            [114.655, -107.178],
                            [148.507, -86.898],
                            [114.655, -66.615],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 11',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 11,
                      ty: 'sh',
                      ix: 12,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 37.495],
                            [50.321, 6.068],
                            [0.313, 0],
                            [0.067, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [2.891, -0.355],
                            [0.021, 0],
                            [0.068, 0],
                            [13.19, -3.409],
                            [0.109, -0.054],
                            [0, -13.284],
                            [-23.888, -20.976],
                            [0, 0],
                            [-10.679, -2.088],
                            [-1.27, 0],
                            [-3.309, 2.209],
                            [-0.996, 5.169],
                            [0, 0],
                            [-21.754, -2.141],
                            [0, 0],
                            [-10.891, 0],
                            [0, 10.884],
                            [0, 0],
                            [-17.553, 6.465],
                            [0, 0],
                            [-9.261, 0],
                            [-1.27, 0.244],
                            [-2.946, 4.378],
                            [1.01, 5.183],
                          ],
                          o: [
                            [28.983, -21.4],
                            [0, -19.571],
                            [-0.3, -0.054],
                            [-0.082, 0],
                            [-6.997, -0.832],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.014, 0],
                            [-0.054, 0],
                            [-16.278, 1.937],
                            [-0.109, 0.042],
                            [-27.647, 7.161],
                            [0, 33.799],
                            [0, 0],
                            [-2.08, 10.693],
                            [1.275, 0.244],
                            [3.894, 0],
                            [4.37, -2.96],
                            [0, 0],
                            [17.137, 7.475],
                            [0, 0],
                            [0, 10.884],
                            [10.898, 0],
                            [0, 0],
                            [21.803, -1.256],
                            [0, 0],
                            [1.834, 9.411],
                            [1.247, 0],
                            [5.176, -1.01],
                            [2.946, -4.391],
                            [0, 0],
                          ],
                          v: [
                            [112.28, 3.135],
                            [158, -86.898],
                            [67.463, -125.727],
                            [66.576, -125.905],
                            [66.358, -125.864],
                            [44.889, -127.924],
                            [35.076, -128.578],
                            [3.209, -129.547],
                            [-6.755, -129.493],
                            [-45.116, -127.665],
                            [-54.623, -126.806],
                            [-63.392, -125.836],
                            [-63.447, -125.85],
                            [-63.631, -125.81],
                            [-108.334, -117.79],
                            [-108.661, -117.694],
                            [-154.856, -86.898],
                            [-117.397, -3.493],
                            [-135.919, 91.559],
                            [-120.309, 114.732],
                            [-116.489, 115.087],
                            [-105.489, 111.731],
                            [-97.149, 99.129],
                            [-81.525, 18.956],
                            [-22.952, 33.578],
                            [-22.952, 134.317],
                            [-3.202, 154.068],
                            [16.554, 134.317],
                            [16.554, 34.301],
                            [75.81, 22.502],
                            [90.738, 99.129],
                            [110.12, 115.087],
                            [113.905, 114.732],
                            [126.507, 106.384],
                            [129.514, 91.559],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 12',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.101960785687, 0.090196080506, 0.105882354081, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 13,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: 'Layer 1',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [251.572, 394.574, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1.572, 144.574, 0], ix: 1, l: 2 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                      o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    { t: 25, s: [100, 100, 100] },
                  ],
                  ix: 6,
                  l: 2,
                  x: 'var $bm_rt;\nfunction easeandwizz_outExpo(t, b, c, d) {\n    var OUT_EXPO_CORRECTION = 1.000976;\n    return t == d ? b + c : c * OUT_EXPO_CORRECTION * (-Math.pow(2, -10 * t / d) + 1) + b;\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_outExpo(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_outExpo(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_outExpo(t, sY, eY, d);\n            val3 = easeandwizz_outExpo(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;',
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: 'Fill',
                  np: 9,
                  mn: 'ADBE Fill',
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: 'Fill Mask',
                      mn: 'ADBE Fill-0001',
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: 'All Masks',
                      mn: 'ADBE Fill-0007',
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: 'Color',
                      mn: 'ADBE Fill-0002',
                      ix: 3,
                      v: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                        x: "var $bm_rt;\n$bm_rt = thisComp.layer('CONTROLS').effect('Color_1')('ADBE Color Control-0001');",
                      },
                    },
                    {
                      ty: 7,
                      nm: 'Invert',
                      mn: 'ADBE Fill-0006',
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: 'Horizontal Feather',
                      mn: 'ADBE Fill-0003',
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: 'Vertical Feather',
                      mn: 'ADBE Fill-0004',
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: 'Opacity',
                      mn: 'ADBE Fill-0005',
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [5.55, -1.091],
                            [1.07, 5.552],
                            [0, 0],
                            [-5.906, 3.697],
                            [0, 0],
                          ],
                          o: [
                            [-5.566, 1.064],
                            [0, 0],
                            [6.744, -2.946],
                            [0, 0],
                            [1.078, 5.552],
                          ],
                          v: [
                            [112.091, 105.429],
                            [100.047, 97.314],
                            [84.757, 18.916],
                            [103.757, 8.959],
                            [120.212, 93.386],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [5.661, 0],
                            [0, 5.661],
                            [0, 0],
                            [-5.108, 0],
                            [-1.827, 0.04],
                          ],
                          o: [
                            [0, 5.661],
                            [-5.668, 0],
                            [0, 0],
                            [4.925, 0.286],
                            [1.848, 0],
                            [0, 0],
                          ],
                          v: [
                            [7.068, 134.317],
                            [-3.202, 144.574],
                            [-13.473, 134.317],
                            [-13.473, 34.301],
                            [1.565, 34.765],
                            [7.068, 34.683],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 2',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 2,
                      ty: 'sh',
                      ix: 3,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [5.552, 1.05],
                            [-1.091, 5.552],
                            [0, 0],
                            [-6.697, -3.437],
                          ],
                          o: [
                            [-1.084, 5.552],
                            [-5.557, -1.091],
                            [0, 0],
                            [5.756, 4.242],
                            [0, 0],
                          ],
                          v: [
                            [-106.451, 97.314],
                            [-118.495, 105.429],
                            [-126.611, 93.386],
                            [-109.042, 3.217],
                            [-90.356, 14.757],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 3',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ind: 3,
                      ty: 'sh',
                      ix: 4,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [9.656, 66.027],
                            [-15.017, -3.887],
                            [-0.191, -0.067],
                            [-15.951, -1.909],
                            [-0.3, 0],
                            [-0.067, 0],
                            [-14.668, -0.559],
                            [-0.04, 0],
                            [-0.028, 0],
                            [-7.175, 0],
                            [-7.175, 0.26],
                            [-0.049, 0],
                            [-0.068, 0.013],
                            [-13.782, 1.623],
                            [-0.082, 0],
                            [-0.279, 0.068],
                            [-15.208, 10.733],
                            [67.78, 0],
                          ],
                          o: [
                            [8.116, 5.728],
                            [0.176, 0.082],
                            [12.957, 3.314],
                            [0.286, 0.068],
                            [0.082, 0],
                            [13.796, 1.623],
                            [0.049, 0],
                            [0.028, 0],
                            [7.187, 0.26],
                            [7.161, 0],
                            [0.04, 0],
                            [0.06, 0],
                            [14.648, -0.546],
                            [0.075, 0],
                            [0.3, 0],
                            [34.739, -4.173],
                            [-9.663, 66.027],
                            [-67.78, 0],
                          ],
                          v: [
                            [-144.164, -70.557],
                            [-108.687, -56.1],
                            [-108.115, -55.936],
                            [-64.292, -48.066],
                            [-63.447, -47.875],
                            [-63.229, -47.915],
                            [-20.231, -44.643],
                            [-20.102, -44.629],
                            [-20.019, -44.629],
                            [1.565, -44.234],
                            [23.108, -44.629],
                            [23.238, -44.601],
                            [23.435, -44.656],
                            [66.364, -47.915],
                            [66.576, -47.875],
                            [67.421, -48.066],
                            [147.307, -70.557],
                            [1.565, 25.286],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 4',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.101960785687, 0.090196080506, 0.105882354081, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        }}
      />
      <div className="progress-wrap">
        <div className="progress2 progress-moved">
          <div className="progress-bar2"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
