import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../config/axios';
import { v4 as uuidv4 } from 'uuid';

// Save user-game
export const createUser = createAsyncThunk(
  'users/createUser',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await API.post('/', formData);
      //console.log(response);
      return response;
    } catch (error) {
      //console.log(error.response);
      return rejectWithValue(error.response);
    }
  }
);

// Generate unique guest token
export const generateGuestToken = () => {
  return `guest_${uuidv4()}`;
};
