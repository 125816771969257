// Import React stuff
import React from 'react';

const Card = ({ relatedProduct, relatedImg }) => {
  return (
    <div className="card col-md-6">
      <img className="card-img-top" src={relatedImg} alt={relatedImg} />
      <div className="card-body">
        <p className="text-blue fw-normal">{relatedProduct}</p>
      </div>
    </div>
  );
};

const Cards = ({ product, images }) => {
  const related1 = product.related1;
  const related2 = product.related2;
  return (
    <div className="cards-wrapper row g-0">
      <Card
        key={related1.relatedProductName}
        relatedProduct={related1.relatedProductDesc}
        relatedImg={images[`${related1.relatedProductImg}`]}
      />

      <Card
        key={related2.relatedProductName}
        relatedProduct={related2.relatedProductDesc}
        relatedImg={images[`${related2.relatedProductImg}`]}
      />
    </div>
  );
};

export default Cards;
