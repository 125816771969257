import React from 'react';

// Import animation, motion
import { motion } from 'framer-motion';
import { child, exitComponent } from '../utils/animations';

const OptForm = ({ quizIntro, formData, handleChange, formErrors, unvalidEmail, reusedEmail }) => {
  return (
    <motion.div variants={child} className="optin-form">
      <div className="form-group">
        <input
          type="text"
          className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
          name="name"
          placeholder="Tvoje ime"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          className={`form-control ${formErrors.email || unvalidEmail ? 'is-invalid' : ''}`}
          name="email"
          placeholder="Elektronski naslov"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {formErrors.name || formErrors.email ? (
          <div className="invalid-feedback">
            <p className="warning-text">Vnesi manjkajoče podatke.</p>
          </div>
        ) : unvalidEmail ? (
          <div className="invalid-feedback">
            <p className="warning-text">Email ni veljaven.</p>
          </div>
        ) : null}
      </div>
      <div className="mt-2">
        <div className="form-check">
          <input
            className={`form-check-input ${formErrors.conditions ? 'is-invalid' : ''}`}
            type="checkbox"
            name="conditions"
            id="conditions-check"
            checked={formData.conditions}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="conditions-check">
            Strinjam se s{' '}
            <a href={quizIntro.pravilaPogoji} target="_blank" rel="noreferrer noopener">
              pravili in pogoji kviza
            </a>{' '}
            in želim sodelovati v nagradni igri.
          </label>
        </div>
        <div className="form-check">
          <input
            className={`form-check-input ${formErrors.processConditions ? 'is-invalid' : ''}`}
            type="checkbox"
            name="processingCond"
            id="processingCond-check"
            checked={formData.processingCond}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="processingCond-check">
            Strinjam se z{' '}
            <a href={quizIntro.pravilaPogoji} target="_blank" rel="noreferrer noopener">
              obdelavo podatkov
            </a>{' '}
            za namen izvedbe nagradne igre.
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="subscribe"
            id="subscribe-check"
            checked={formData.subscribe}
            onChange={handleChange}
          />

          <label className="form-check-label" htmlFor="subscribe-check">
            {quizIntro.optinSub}
          </label>
        </div>

        <div>
          {formErrors.conditions || formErrors.processConditions ? (
            <p style={{ color: '#DC3545' }} className="warning-text">
              Za nadaljevanje sprejmi pogoje.
            </p>
          ) : null}
        </div>
        {reusedEmail && (
          <motion.div
            variants={exitComponent}
            initial="visible"
            animate="visible"
            exit="exit"
            className=""
          >
            <p style={{ color: '#DC3545' }} className="warning-text">
              Ta email je bil že uporabljen. Nadaljuj kot gost.
            </p>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default OptForm;
