import { createSlice } from '@reduxjs/toolkit';
import { createUser } from './actions';

const initialState = {
  showStartOptin: true,
  showEndOptin: false,
  users: [],
  user: false,
  isLoading: false,
  error: null,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setShowStartOptin: (state, action) => {
      state.showStartOptin = action.payload;
    },

    setShowEndOptin: (state, action) => {
      state.showEndOptin = action.payload;
    },

    // User data (name, email) is submitted (true/false)
    setUser: (state, action) => {
      state.user = action.payload;
    },

    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.user = true;
        }
        state.isLoading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const { setShowStartOptin, setShowEndOptin, setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
