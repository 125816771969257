import { emojisplosion } from 'emojisplosion';

export function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace('./', '')] = r(item));
  });
  return images;
}

// Email validation
export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/u;
  return emailRegex.test(email);
};

// Form validation
export const validateForm = (form) => {
  const formErrors = {};

  if (form.name.trim() === '') {
    formErrors.name = true;
  }

  if (form.email.trim() === '') {
    formErrors.email = true;
  }

  if (!form.conditions) {
    formErrors.conditions = true;
  }

  if (!form.processingCond) {
    formErrors.processConditions = true;
  }

  return formErrors;
};

// Social share
export const shareContent = (platform, title, url, text, image) => {
  window.plausible('social_share');

  switch (platform) {
    case 'facebook':
      // Facebook Share
      window.open(
        `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
          url
        )}&quote=${encodeURIComponent(text)}`,
        '_blank'
      );
      break;

    case 'twitter':
      // Twitter Share
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text
      )}&url=${encodeURIComponent(url)}`;
      window.open(twitterUrl, '_blank');
      break;

    case 'messenger':
      // Facebook Messenger Share
      const fbMessengerUrl = `fb-messenger://share/?link=${encodeURIComponent(url)}`;
      window.open(fbMessengerUrl, '_blank');
      break;

    case 'whatsapp':
      // WhatsApp Share
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        text
      )}%20${encodeURIComponent(url)}`;
      window.open(whatsappUrl, '_blank');
      break;

    default:
      // Default share behavior
      if (navigator.share) {
        navigator
          .share({ title, url, text, image })
          .then(() => {
            //console.log("Thanks for sharing!");
          })
          .catch(console.error);
      }
      break;
  }
};

// Emoji explosion
var cumulativeOffset = function (element) {
  var top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
};

export const triggerEmojiExplosion = (element, emoji, count, size) => {
  emojisplosion({
    emojis: emoji,
    container: document.getElementById('explosion'),
    emojiCount: count,
    fontSize: size,
    physics: {
      gravity: 0.25,
      initialVelocities: {
        rotation: {
          max: 7,
          min: -7,
        },
        y: {
          max: 20,
          min: -20,
        },
        x: {
          max: 20,
          min: -20,
        },
      },
      rotationDecelaration: 1.01,
    },
    position() {
      const offset = cumulativeOffset(element);

      return {
        x: offset.left + element.clientWidth / 2,
        y: offset.top + element.clientHeight / 2,
      };
    },
  });
};

// Explosion overlay
export const showOverlay = () => {
  const element = document.getElementById('explosion');
  if (element) {
    element.style.display = 'block';
  }
};

export const hideOverlay = () => {
  const element = document.getElementById('explosion');
  if (element) {
    element.style.display = 'none';
  }
};

// Refresh Iframe
// export const refreshIframe = () => {
//   // Access the parent window
//   const parentWindow = window.parent;

//   // Access the iframe element
//   const iframe = parentWindow.document.querySelector('iframe[data-target="ID115978341"]');

//   // Reload the iframe content
//   iframe.src = iframe.src;
// };
